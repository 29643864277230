import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';

const ContactForm = (props) => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        jurusan: '',
        phone: '',
        message: ''
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value });
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = e => {
        e.preventDefault();
        if (validator.allValid()) {
            // Redirect to Google Form with query parameters
            const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSf0y-H4MwzaAMgusGnBvZXJUTS_kEdbeFW38mv8tV1TtvjRcA/viewform'; // Ganti dengan Google Form URL Anda
            
            const queryString = `?entry.1568770362=${encodeURIComponent(forms.name)}&entry.833768572=${encodeURIComponent(forms.npm)}&entry.966546854=${encodeURIComponent(forms.kelas)}&entry.1616057047=${encodeURIComponent(forms.email)}&entry.2146807154=${encodeURIComponent(forms.jurusan)}&entry.1202393292=${encodeURIComponent(forms.phone)}&entry.1205207810=${encodeURIComponent(forms.divisi)}&entry.769622830=${encodeURIComponent(forms.message)}`;

            // Redirect to Google Forms with query string
            window.open(googleFormUrl + queryString, '_blank');

        } else {
            validator.showMessages();
        }
    };

    return (
        <form className="xb-item--form contact-from" onSubmit={(e) => submitHandler(e)}>
            <div className="row">
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_name">
                            <i className="fa-regular fa-user"></i>
                        </label>
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Nama" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_npm">
                            <i className="fa-regular fa-user"></i>
                        </label>
                        <input
                            value={forms.npm}
                            type="string"
                            name="npm"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="NPM" />
                        {validator.message('npm', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_kelas">
                            <i className="fa-regular fa-envelope"></i>
                        </label>
                        <input
                            value={forms.kelas}
                            type="string"
                            name="kelas"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Kelas (cth: Reguler Pagi)" />
                        {validator.message('kelas', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_email">
                            <i className="fa-regular fa-envelope"></i>
                        </label>
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_phone">
                            <i className="fa-regular fa-phone-volume"></i>
                        </label>
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="No.HP" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_jurusan">
                            <i className="fa-regular fa-globe"></i>
                        </label>
                        <input
                            value={forms.jurusan}
                            type="jurusan"
                            name="jurusan"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Jurusan" />
                        {validator.message('jurusan', forms.jurusan, 'required')}
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_divisi">
                            <i className="fa-regular fa-globe"></i>
                        </label>
                        <input
                            value={forms.divisi}
                            type="divisi"
                            name="divisi"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Tertarik masuk divisi apa? (Hardware/Software/Game)" />
                        {validator.message('divisi', forms.divisi, 'required')}
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group">
                        <label className="input_title" htmlFor="input_textarea">
                            <i className="fa-regular fa-comments"></i>
                        </label>
                        <textarea
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.message}
                            type="text"
                            name="message"
                            className="form-control"
                            placeholder="Kenapa tertarik Masuk Oxigen?">
                        </textarea>
                        {validator.message('message', forms.message, 'required')}
                    </div>
                    <button type="submit" className="btn btn-primary">
                        <span className="btn_label" data-text="Send Request">Kirim Data Diri</span>
                        <span className="btn_icon">
                            <i className="fa-solid fa-arrow-up-right"></i>
                        </span>
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
