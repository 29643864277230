import React from 'react'
import Bg from '../../../images/shapes/shape_title_under_line.svg'
import ContactForm from '../ContactFrom/ContactForm'
import shape1 from '../../../images/shapes/shape_line_5.svg'
import shape2 from '../../../images/shapes/shape_line_6.svg'
import shape3 from '../../../images/shapes/shape_space_5.svg'


const ContactSection = (props) => {
    return (
        <section className="contact_section pb-80 bg-light section_decoration">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="contact_method_box">
                            <div className="heading_block">
                                <div className="heading_focus_text has_underline d-inline-flex mb-3" style={{ backgroundImage: `url(${Bg})` }}>
                                    Kamu siap ?
                                </div>
                                <h2 className="heading_text mb-0">
                                    Ayo Daftar
                                </h2>
                                <p className="heading_description mb-0">UKM Oxigen menerima semua Jurusan</p>
                            </div>
                            <ul className="contact_method_list unordered_list_block">
                                <li>
                                    <a href="tel:+8801680636189">
                                        <span className="icon">
                                            <i className="fa-solid fa-phone-volume"></i>
                                        </span>
                                        <span className="text">+62-xxx xxx</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="mailto:oxigen.sttb1@gmail.com">
                                        <span className="icon">
                                            <i className="fa-solid fa-envelope"></i>
                                        </span>
                                        <span className="text">oxigen.sttb1@gmail.com</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.google.com/maps?q=Jl.+Soekarno+Hatta+No.378,+Kb.+Lega,+Kec.+Bojongloa+Kidul,+Kota+Bandung,+Jawa+Barat+40235" target="_blank">
                                        <span className="icon">
                                            <i className="fa-solid fa-location-dot"></i>
                                        </span>
                                        <span className="text">Jl. Soekarno Hatta No.378, Kb. Lega, Kec. Bojongloa Kidul, Kota Bandung, Jawa Barat 40235</span>
                                    </a>
                                </li>

                            </ul>
                            <ul className="support_step unordered_list_block">
                                <li>
                                    <span className="serial_number">01</span>
                                    <span className="text">Pendataan Diri</span>
                                </li>
                                <li>
                                    <span className="serial_number">02</span>
                                    <span className="text">Sesi Tes Tulis</span>
                                </li>
                                <li>
                                    <span className="serial_number">03</span>
                                    <span className="text">Sesi Wawancara</span>
                                </li>
                                <li>
                                    <span className="serial_number">04</span>
                                    <span className="text">Hasil Keputusan</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="instant_contact_form">
                            <div className="small_title">
                                <i className="fa-solid fa-envelope-open-text"></i>
                                {" "}
                                Form Google
                            </div>
                            <h3 className="form_title">
                                Jika kamu siap, Silahkan masukan data dan kirim data diri kamu untuk menjadi bagian dari UKM OXIGEN.
                            </h3>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="decoration_item shape_image_1">
                <img src={shape1} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_2">
                <img src={shape2} alt="Techco Shape" />
            </div>
            <div className="decoration_item shape_image_3">
                <img src={shape3} alt="Techco Shape" />
            </div>
        </section>
    )
}

export default ContactSection;